import type { HTTPHeaders } from '../types';

import {
    APIResponseError, delay, getAPIDomain, getSDKAuthHeader, promiseTry,
    stringifyError, UnhandledError
} from '../lib';

import type { Milliseconds } from '@onetext/api';
import { HTTP_CONTENT_TYPE, HTTP_HEADER } from '@onetext/api';

enum HTTP_METHOD {
    GET = 'GET',
    POST = 'POST'
}

type CallOneTextAPIOptions = {
    method ?: HTTP_METHOD,
    path : string,
    body ?: unknown,
    allowedRetriesOnNetworkError ?: number,
    allowedRetriesOnServerError ?: number,
};

export type CallOneTextAPIResult<ResponseBodyType> = {
    status : number,
    body : ResponseBodyType,
};

export const callOneTextAPI = <ResponseBodyType>(
    opts : CallOneTextAPIOptions
) : Promise<CallOneTextAPIResult<ResponseBodyType>> => {
    const {
        method = HTTP_METHOD.POST,
        path,
        body,
        allowedRetriesOnNetworkError = 3,
        // Pass idempotency id before increasing
        allowedRetriesOnServerError = 0
    } = opts;

    const headers : { [ key in HTTP_HEADER ] ?: string } = {
        [ HTTP_HEADER.CONTENT_TYPE ]: HTTP_CONTENT_TYPE.JSON
    };

    const authHeader = getSDKAuthHeader();

    if (authHeader) {
        headers[ HTTP_HEADER.AUTHORIZATION ] = authHeader;
    }

    return fetch(`${ getAPIDomain() }/api/${ path }`, {
        method,
        headers,
        body: JSON.stringify(body)
    }).then(res => {
        const contextID = res.headers.get(HTTP_HEADER.ONETEXT_CONTEXT_ID);
        const responseStatus = res.status;
        const isServerError = responseStatus.toString().charAt(0) === '5';

        const responseHeaders : HTTPHeaders = {};

        for (const [ key, value ] of res.headers.entries()) {
            if (key && value) {
                responseHeaders[key.toLowerCase()] = value;
            }
        }

        const allowRetry = responseHeaders[HTTP_HEADER.ALLOW_RETRY] !== 'false';

        if (
            isServerError &&
            allowRetry &&
            allowedRetriesOnServerError > 0
        ) {
            return delay(1000 as Milliseconds).then(() => {
                return callOneTextAPI({
                    ...opts,
                    allowedRetriesOnServerError: allowedRetriesOnServerError - 1
                });
            });
        }

        if (!res.ok) {
            throw new APIResponseError({
                message: `SDK API call to ${ method } ${ path } failed with status ${ res.status }${ contextID
                    ? ` (context id: ${ contextID })`
                    : '' }`,
                status:    responseStatus,
                headers:   responseHeaders,
                body:      undefined,
                hardError: isServerError
            });
        }

        return res.json().then(json => {
            return {
                status: responseStatus,
                body:   json as ResponseBodyType
            };
        }, err => {
            throw new APIResponseError({
                message: `SDK API call to ${ method } ${ path } response read failed with error ${ contextID
                    ? `(context id: ${ contextID })`
                    : '' }\n\n${ stringifyError(err) }`,
                status:    responseStatus,
                headers:   responseHeaders,
                body:      undefined,
                hardError: true
            });
        });
    }, err => {
        if (allowedRetriesOnNetworkError > 0) {
            return delay(1000 as Milliseconds).then(() => {
                return callOneTextAPI({
                    ...opts,
                    allowedRetriesOnNetworkError: allowedRetriesOnNetworkError - 1
                });
            });
        }

        throw new UnhandledError({
            message:       `SDK API call to ${ method } ${ path } failed`,
            originalError: err
        });
    });
};

type ConstructOneTextURLOptions = {
    path : string,
    query ?: Record<string, string>,
};

export const constructOneTextURL = ({
    path,
    query
} : ConstructOneTextURLOptions) : string => {
    const queryString = new URLSearchParams(query).toString();
    return `${ getAPIDomain() }/${ path }?${ queryString }`;
};

type RedirectInPopupOptions = {
    url : string,
};

export const redirectInPopup = ({
    url
} : RedirectInPopupOptions) : Promise<void> => {
    return promiseTry(() => {
        window.open(url, '_blank');
    });
};
