import type { Cancelable } from '../types';

import { type Email, ENV, LOCAL_PHONE_AREA_CODE, type Milliseconds, type PhoneNumber } from '@onetext/api';

const envs = Object.values(ENV);

// eslint-disable-next-line security/detect-non-literal-regexp
const tokenRegex = new RegExp(`^onetext(?:_\\w+)+_(${ envs.join('|') })_`);

export const tokenToEnv = (token : string) : ENV => {
    const match = token.match(tokenRegex);

    if (!match) {
        throw new Error(`Can not determine SDK environment`);
    }

    const env = match[1];

    if (!env) {
        throw new Error(`Can not determine SDK environment`);
    }

    if (!envs.includes(env)) {
        throw new Error(`Invalid SDK environment: ${ env }`);
    }

    return env as ENV;
};

export const run = <Type>(handler : () => Type) : Type => {
    return handler();
};

export const promiseTry = <Type>(handler : () => Promise<Type> | Type) : Promise<Type> => {
    let result;

    try {
        result = handler();
    } catch (err) {
        // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
        return Promise.reject(err);
    }

    return Promise.resolve(result);
};

let localStorageEnabled : boolean | undefined;

export const isLocalStorageEnabled = () : boolean => {
    if (localStorageEnabled !== undefined) {
        return localStorageEnabled;
    }

    try {
        if (typeof window === 'undefined') {
            return false;
        }

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (window.localStorage) {
            const value = Math.random().toString();
            window.localStorage.setItem('__test__localStorage__', value);
            const result = window.localStorage.getItem('__test__localStorage__');
            window.localStorage.removeItem('__test__localStorage__');

            if (value === result) {
                localStorageEnabled = true;
                return true;
            }
        }
    } catch {
        // pass
    }

    localStorageEnabled = false;
    return false;
};

const memoryLocalStorage : {
    [ key : string ] : string | undefined,
} = {};

export const localStorageSet = (key : string, value : string) : string => {
    memoryLocalStorage[key] = value;

    if (!isLocalStorageEnabled()) {
        return value;
    }

    try {
        window.localStorage.setItem(key, value);
    } catch {
        // pass
    }

    return value;
};

export const localStorageGet = (key : string) : string | undefined => {
    if (key in memoryLocalStorage) {
        return memoryLocalStorage[key];
    }

    if (!isLocalStorageEnabled()) {
        return;
    }

    try {
        return window.localStorage.getItem(key) ?? undefined;
    } catch {
        // pass
    }
};

export const getUserAgent = () : string => {
    return window.navigator.mockUserAgent ?? window.navigator.userAgent;
};

export const isDevice = (userAgent : string = getUserAgent()) : boolean => {
    if ((/android|webos|iphone|ipad|ipod|bada|symbian|palm|crios|blackberry|iemobile|windowsmobile|opera mini/i).test(userAgent)) {
        return true;
    }

    return false;
};

export const noop = () : void => {
    // pass
};

export const assertExists = <T>(thing : undefined | null | T) : T => {
    if (thing === null || thing === undefined) {
        throw new Error(`Expected value to be present`);
    }

    return thing;
};

export const getStackTrace = () : string => {
    try {
        throw new Error('_');
    } catch (err) {
        return (err as Error).stack ?? '';
    }
};

const inferCurrentScript = () : HTMLScriptElement | undefined => {
    try {
        const stack = getStackTrace();
        const stackDetails = (/.*at [^(]*\((.*):(.+):(.+)\)$/gi).exec(stack);
        const scriptLocation = stackDetails?.[1];

        if (!scriptLocation) {
            return;
        }

        for (const element of Array.prototype.slice.call(document.getElementsByTagName('script')).reverse()) {
            const script = element as HTMLScriptElement;

            if (script.src && script.src === scriptLocation) {
                return script;
            }
        }
    } catch {
        // pass
    }
};

let currentScript : HTMLScriptElement | undefined = typeof document === 'undefined'
    ? undefined
    : document.currentScript as HTMLScriptElement | undefined;

type GetCurrentScript = () => HTMLScriptElement;

export const getCurrentScript : GetCurrentScript = () => {
    if (currentScript) {
        return currentScript;
    }

    currentScript = inferCurrentScript();

    if (currentScript) {
        return currentScript;
    }

    throw new Error('Can not determine current script');
};

export const getCurrentScriptURL = () : string => {
    return getCurrentScript().src;
};

export const getCurrentScriptBasePath = () : string => {
    return getCurrentScriptURL().replace(/[^/]*$/, '');
};

export const getBody = () : HTMLBodyElement => {
    const body = document.body as HTMLBodyElement | undefined;

    if (!body) {
        throw new Error(`Body element not found`);
    }

    return body;
};

export const debounce = <
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FunctionType extends (...args : Array<any>) => any
>(
    func : FunctionType,
    delay : number
) : ((...args : Parameters<FunctionType>) => void) => {
    let timeoutId : ReturnType<typeof setTimeout>;

    const wrapper = (...args : ReadonlyArray<unknown>) : void => {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };

    return wrapper;
};

export const debouncePromise = <
    FunctionReturnType,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FunctionType extends (...args : Array<any>) => Promise<FunctionReturnType>,
    FinalReturnType extends Awaited<ReturnType<FunctionType>>
>(
    func : FunctionType,
    delay : number
) : ((...args : Parameters<FunctionType>) => Promise<FinalReturnType>) => {
    let timer : ReturnType<typeof setTimeout>;

    let promise : Promise<FinalReturnType> | undefined;
    let resolve : ((value : FinalReturnType) => void) | undefined;
    let reject : ((err : unknown) => void) | undefined;

    const wrapper = (...args : ReadonlyArray<unknown>) : Promise<FinalReturnType> => {
        clearTimeout(timer);

        // eslint-disable-next-line promise/param-names
        promise ??= new Promise<FinalReturnType>((res, rej) => {
            resolve = res;
            reject = rej;
        });

        const innerResolve = resolve;
        const innerReject = reject;

        if (!innerResolve || !innerReject) {
            throw new Error('Promise not initialized');
        }

        timer = setTimeout(() => {
            void func(...args).then(result => {
                innerResolve(result as unknown as FinalReturnType);
            }, innerReject).finally(() => {
                promise = undefined;
                resolve = undefined;
                reject = undefined;
            });
        }, delay);

        return promise;
    };

    return wrapper;
};

type Eventable = {
    addEventListener : (type : string, handler : (event : Event) => void) => void,
    removeEventListener : (type : string, handler : (event : Event) => void) => void,
};

export const listen = (
    item : Eventable,
    eventName : string,
    handler : (event : Event) => void
) : Cancelable => {
    item.addEventListener(eventName, handler);

    return {
        cancel: () => {
            item.removeEventListener(eventName, handler);
        }
    };
};

type MemoizeOptions = {
    ttl ?: number,
};

export const memoize = <
    FunctionType extends (...args : Array<unknown>) => unknown
>(
    func : FunctionType,
    opts : MemoizeOptions = {}
) : ((...args : Parameters<FunctionType>) => ReturnType<FunctionType>) => {
    const {
        ttl = 5 * 60 * 1000
    } = opts;

    let result : {
        value : ReturnType<FunctionType>,
        timestamp : number,
    } | undefined;

    return (...args : Parameters<FunctionType>) => {
        const now = Date.now();

        if (result && now - result.timestamp < ttl) {
            return result.value;
        }

        result = {
            value:     func(...args) as ReturnType<FunctionType>,
            timestamp: now
        };

        return result.value;
    };
};

export const assertUnreachable = (value : never) : Error => {
    throw new Error(`Unreachable value: ${ JSON.stringify(value) }`);
};

export type ExpandedPromise<Type> = {
    promise : Promise<Type>,
    resolve : (value : Type) => void,
    reject : (error : unknown) => void,
};

export const createPromise = <Type>() : ExpandedPromise<Type> => {
    let promiseResolve;
    let promiseReject;

    const promise = new Promise<Type>((resolve, reject) => {
        promiseResolve = resolve;
        promiseReject = reject;
    });

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!promiseResolve || !promiseReject) {
        throw new Error('Promise not initialized');
    }

    return {
        promise,
        resolve: promiseResolve,
        reject:  promiseReject
    };
};

const UNIQUE_ID_CHARS = '0123456789abcdef';

export const uniqueID = () : string => {
    return 'xxxxxxxxxx'.replaceAll('x', () => {
        return UNIQUE_ID_CHARS.charAt(Math.floor(Math.random() * UNIQUE_ID_CHARS.length));
    });
};

export const isValidEmail = (email : string) : email is Email => {
    if (!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(email)) {
        return false;
    }

    return true;
};

export const isValidLocalPhone = (phone : string) : phone is PhoneNumber => {
    if (!(/^\d{10}$/).test(phone)) {
        return false;
    }

    if (
        phone.startsWith('0') ||
        phone.startsWith('1')
    ) {
        return false;
    }

    if (
        phone.slice(3, 4) === '0' ||
        phone.slice(3, 4) === '1'
    ) {
        return false;
    }

    return true;
};

const localPhoneAreaCodes = Object.values(LOCAL_PHONE_AREA_CODE);

export const isValidLocalUSPhone = (phone : string) : phone is PhoneNumber => {
    if (!isValidLocalPhone(phone)) {
        return false;
    }

    const firstThree = phone.slice(0, 3);

    if (!localPhoneAreaCodes.includes(firstThree)) {
        return false;
    }

    return true;
};

export const identity = <Type>(value : Type) : Type => {
    return value;
};

export const randomInteger = (min : number, max : number) : number => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const delay = (ms : Milliseconds) : Promise<void> => new Promise(resolve => {
    setTimeout(resolve, ms);
});
